import { Stack, Badge, clsx, Center, ActionIcon, Text, Code, Highlight, Chip } from '@mantine/core';
import {
  IconCloudUpload,
  IconFileInvoice,
  IconMoodHappy,
  IconSquareFilled,
  IconUser,
} from '@tabler/icons-react';
import { format_currency } from 'utils/format';
import styles from './DashboarPage.module.scss';

export const columnsDefsArray = [
  {
    accessor: 'display_name',
    sortable: true,
    title: 'Name',
    width: 200,
    render: (record: any) => {
      return (
        <Stack align="flex-start" style={{ gap: 5 }}>
          <Text>{record.display_name}</Text>
          {record.display_name ? (
            <>
              {record.is_alumni ? (
                <Badge variant="light" className={clsx(styles.alumni, 'green')}>
                  Alumni
                </Badge>
              ) : (
                <Badge variant="light" className={clsx(styles.alumni, 'blue')}>
                  non-Alumni
                </Badge>
              )}
            </>
          ) : null}
        </Stack>
      );
    },
  },
  {
    title: 'Course Date',
    accessor: 'course_date',
    width: 120,
    sortable: true,
  },
  {
    title: 'Course Title',
    accessor: 'course_title',
    width: 300,
    sortable: true,
  },

  {
    width: 150,
    title: 'Sales Revenue',
    accessor: 'sales_revenue',
    sortable: true,
    cellsStyle: { fontWeight: '500', textAlign: 'center' },
    render: (record: any) => {
      return record.sales_revenue ? format_currency(record.sales_revenue) : '-';
    },
  },
  {
    width: 150,
    title: 'Commission',
    accessor: 'commission',
    sortable: true,
    cellsStyle: { fontWeight: '500', textAlign: 'center' },
    render: (record: any) => {
      return record.commission ? format_currency(record.commission) : '-';
    },
  },
  {
    title: 'Remarks',
    accessor: 'remark',
    width: 300,
    sortable: true,
    cellsStyle: { textAlign: 'center' },
    render: (record: any) => {
      return (
        <Stack>
          {record.lead_referral_remarks ? (
            <div>
              <span>Remark:</span>
              <Text>{record.lead_referral_remarks}</Text>
            </div>
          ) : null}

          {record.appt_referral_remarks ? (
            <div>
              <Text weight={'bold'}> Remark:</Text>
              <Text>{record.appt_referral_remarks}</Text>
            </div>
          ) : null}

          {!record.lead_referral_remarks || !record.appt_referral_remarks ? '-' : null}
        </Stack>
      );
    },
  },
  {
    width: 150,
    title: 'Lead Status',
    accessor: 'display_lead_status',
    sortable: true,
    render: function (record: any) {
      return (
        <Center style={{ textAlign: 'center' }}>
          {record.display_lead_status ? (
            <Badge variant="filled" className={clsx(styles.datatable_status_badge, 'blue')}>
              {record.display_lead_status}
            </Badge>
          ) : (
            '-'
          )}
        </Center>
      );
    },
  },
  {
    width: 150,
    title: 'Registration Status',
    accessor: 'ordersubmission_status',
    sortable: true,
    render: function (record: any) {
      const color =
        (
          {
            ['Submitted']: 'blue',
            ['Approved']: 'green',
            ['Pending']: 'blue',
            ['Rejected']: 'red',
            ['S.Rejected']: 'red',
          } as any
        )[record.ordersubmission_status] || 'blue';
      return (
        <Center style={{ textAlign: 'center' }}>
          {record.ordersubmission_status ? (
            <Badge variant="light" className={clsx(styles.datatable_status_badge, color)}>
              {record.ordersubmission_status}
            </Badge>
          ) : (
            '-'
          )}
        </Center>
      );
    },
  },
  {
    width: 150,
    title: 'Attendance Status',
    sortable: true,
    accessor: 'attendance_status',
    render: function (record: any) {
      const color =
        (
          {
            ['Completed']: 'green',
            ['Pending']: 'yellow',
            ['Reschedule']: 'yellow',
            ['Cancelled']: 'red',
          } as any
        )[record.attendance_status] || 'blue';
      return (
        <Center style={{ textAlign: 'center' }}>
          {record.attendance_status ? (
            <Badge className={clsx(styles.datatable_status_badge, color)}>
              {record.attendance_status}
            </Badge>
          ) : (
            '-'
          )}
        </Center>
      );
    },
  },
  {
    title: 'Invoice Status',
    accessor: 'invoice_status',
    sortable: true,
    width: 150,
    render: (record: any) => {
      const color =
        (
          {
            ['Rejected']: 'red',
            ['Paid']: 'green',
            ['Pending']: 'blue',
            ['Submitted']: 'blue',
          } as any
        )[record.invoice_status] || 'blue';

      return (
        <Stack align="center" style={{ gap: 0 }}>
          {record.invoice_status ? (
            <>
              <Badge
                w={'fit-content'}
                className={clsx(styles.datatable_status_badge, color)}
                variant="light"
              >
                {record.invoice_status}
              </Badge>
              {record.partner_invoice_no ? (
                <Text size={'sm'} color="dimmed" mt="sm">
                  <IconFileInvoice size={'0.95em'} />
                  {record.partner_invoice_no}
                </Text>
              ) : null}
            </>
          ) : null}

          {record.invoice_remarks ? (
            <Text size={'sm'} align="center">
              <Text color="red" weight={'bold'} underline>
                Remarks:
              </Text>
              <Text>{record.invoice_remarks}</Text>
            </Text>
          ) : null}
        </Stack>
      );
    },
  },
  {
    title: 'Partner',
    accessor: 'referral_code',
    sortable: true,
    render: (record: any) => {
      return (
        <>
          <Text size={'sm'} style={{ whiteSpace: 'nowrap' }}>
            <IconUser size={'0.9em'} /> {record.partner_user_name}{' '}
            {record.partner_type ? `(${record.partner_type})` : null}
          </Text>
          <Badge radius={'none'} variant="outline" style={{ whiteSpace: 'nowrap' }}>
            {record.used_referral_code}
          </Badge>
        </>
      );
    },
  },
] as const;

export const columnsDefs = Object.fromEntries(
  columnsDefsArray.map((col) => {
    return [col.accessor, col];
  })
);
