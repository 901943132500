import {
  Box,
  SimpleGrid,
  Stack,
  Title,
  Text,
  Center,
  Group,
  Paper,
  ActionIcon,
  Button,
  Loader,
} from '@mantine/core';
import { IconFileInvoice } from '@tabler/icons-react';
import { ReactComponent as RibbonSvg } from 'assets/dashboard/bg vector01.svg';
import { ReactComponent as ReferralStatSvg } from 'assets/dashboard/icona_ref.svg';
import { ReactComponent as ReadyStatSvg } from 'assets/dashboard/icona_ready.svg';
import { ReactComponent as PaidStatSvg } from 'assets/dashboard/icon_paid.svg';
import { ReactComponent as LearnerStatSvg } from 'assets/dashboard/icon_learner.svg';
import { ReactComponent as LeadStatSvg } from 'assets/dashboard/icon_lead.svg';
import { ReactComponent as DownloadSvg } from 'assets/dashboard/icon_dl.svg';
import styles from './DashboarPage.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { format_currency } from 'utils/format';
import { DataTable } from 'components/DataTable';
import { notifications } from 'notifications';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { ResetPasswordFirstTimeModal } from '../Profile/ResetPasswordFirstTimeModal';
import { WelcomeFloatingImage } from './WelcomeFloatingImage';
import { columnsDefsArray } from './columnsRenderer';
import { DataTableSortStatus } from 'mantine-datatable';
import { UploadInvoiceForm } from './UploadInvoiceForm';

export function DashboardPage() {
  const { loginUser } = useLoginUser();
  const [resetFirstTimeModalOpen, resetFirstTimeModalHandler] = useDisclosure(false);
  const [uploadModalOpened, uploadModalHelper] = useDisclosure(false);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [leadTableRefetchKey, setLeadTableRefetchKey] = useState(0);

  const dashboardQuery = useQuery(['dashboard'], () => {
    return API.getDashboard()
      .then((res) => res.data)
      .then((res) => {
        return res.data;
      });
  });

  const handleDownloadLead = useMutation(() => API.getLeads({ output_type: 'download' }), {
    onSuccess: (res) => {
      const blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' });

      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth().toString().padStart(2, '0');
      const date = now.getDate().toString().padStart(2, '0');
      a.download = `lead-${year}-${month}-${date}.xlsx`; // Specify the filename here
      document.body.appendChild(a);
      a.click();

      // Clean up resources
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

      notifications.success({ message: 'Downloaded' });
    },
  });

  function greet() {
    const now = new Date();
    const currentHour = now.getHours();
    let greeting;

    if (currentHour >= 5 && currentHour < 12) {
      greeting = 'Good morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = 'Good afternoon';
    } else {
      greeting = 'Good night';
    }
    return greeting;
  }

  useEffect(() => {
    if (loginUser) {
      if (loginUser.reset_password) {
        resetFirstTimeModalHandler.open();
      }
    }
  }, [loginUser]);

  useEffect(() => {
    // prevent horizontal scroll back and forward navigation
    // because our datatable require horizontal scroll alots
    const cached = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = cached;
    };
  }, []);

  return (
    <Stack px="lg">
      <UploadInvoiceForm
        opened={uploadModalOpened}
        onClose={uploadModalHelper.close}
        selectedRecords={selectedRecords}
        setSelectedRecords={setSelectedRecords}
        onSuccess={() => {
          setLeadTableRefetchKey((key) => key + 1);
        }}
      />
      <ResetPasswordFirstTimeModal
        opened={resetFirstTimeModalOpen}
        onClose={resetFirstTimeModalHandler.close}
      />

      <Box
        bg="#E8F7F2"
        sx={(theme) => ({
          borderRadius: theme.radius.md,
        })}
        style={{ overflow: 'hidden' }}
      >
        <SimpleGrid h="100%" cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          <Center h="100%" px={30} pos={'relative'}>
            {/* <Box pos={'absolute'} top={0} left={0} className={styles.stripe}>
              <RibbonSvg />
            </Box> */}
            <Box py={20}>
              <Title order={2}>
                {greet()},
                <Text display={'inline'} color="teal" ml={4}>
                  {loginUser.partner_user_name}
                </Text>
              </Title>
            </Box>
            <Box className={styles.strip_container}>
              <Box className={styles.strip_background}>
                <RibbonSvg className={styles.strip_image} />
              </Box>
            </Box>
          </Center>
          <WelcomeFloatingImage />
        </SimpleGrid>
      </Box>

      <Box>
        <Title order={2} mb={'lg'}>
          Activity
        </Title>

        <Box>
          {dashboardQuery.isFetching && <Loader />}
          {dashboardQuery.isSuccess && (
            <Group align="stretch" position="center">
              {[
                {
                  amount: format_currency(dashboardQuery.data.upcoming_amount),
                  title: 'Upcoming',
                  subTitle: 'Sign Up Commission',
                  icon: <ReferralStatSvg />,
                  footer: dashboardQuery.data.upcoming_course + ' course(s)',
                  bg: '#D9EDF7',
                  // bg: 'linear-gradient(14deg, #D9EDF7 0%, #DFEDF4 100%)',
                },
                {
                  amount: format_currency(dashboardQuery.data.ready_amount),
                  title: 'Ready for Payout',
                  icon: <ReadyStatSvg />,
                  subTitle: 'Sign Up Commission',
                  footer: dashboardQuery.data.ready_course + ' course(s)',
                  bg: '#DAF3ED',
                  // bg: 'linear-gradient(14deg, #DAF3ED 0%, rgba(221,246,241,1) 100%)',
                },
                {
                  amount: format_currency(dashboardQuery.data.paid_amount),
                  title: 'Paid',
                  icon: <PaidStatSvg />,
                  subTitle: 'Sign Up Commission',
                  footer: dashboardQuery.data.paid_course + ' course(s)',
                  bg: '#FFF5E3',
                  // bg: 'linear-gradient(14deg,  #FFF5E3 0%, rgba(221,246,241,1) 100%)',
                },
                {
                  amount: dashboardQuery.data.number_of_learner,
                  title: 'Number of Learners',
                  icon: <LearnerStatSvg />,
                  // subTitle: 'Referrer Commission',
                  bg: '#ECE3EF',
                  // bg: 'linear-gradient(14deg,  #F4D6FA 0%, #EEE3F0 100%)',
                },
                {
                  amount: dashboardQuery.data.number_of_lead,
                  title: 'Number of Leads',
                  icon: <LeadStatSvg />,
                  bg: '#F5E8ED',
                  // bg: 'linear-gradient(14deg,  #F5E8ED 0%, #F8E4EC 100%)',
                },
              ].map((data) => {
                return (
                  <Paper
                    className={styles.stat_card}
                    // withBorder
                    bg={data.bg}
                    radius="none"
                    p="md"
                    key={data.title}
                    mih={150}
                    style={{ flex: '1 1 0px' }}
                  >
                    <Group style={{ flexWrap: 'nowrap' }} align="flex-start" position="apart">
                      <Box mr={'50px'}>
                        <Text size={'28px'} weight={'700'} style={{ whiteSpace: 'nowrap' }}>
                          {data.amount}
                        </Text>
                        <Text size={'sm'} weight={'bold'}>
                          {data.title}
                        </Text>
                        <Text size={'sm'} weight={'400'}>
                          {data.subTitle}
                        </Text>
                      </Box>
                      <ActionIcon variant="light" size={'xl'} style={{ borderRadius: '50%' }}>
                        {data.icon}
                      </ActionIcon>
                    </Group>
                    <Text mt={10} weight={'300'} size={'sm'}>
                      {data.footer}
                    </Text>
                  </Paper>
                );
              })}
            </Group>
          )}
        </Box>
      </Box>

      <Paper mt={'lg'} p="sm" maw="100%">
        <Group position="apart" align="flex-end" mb="lg">
          <Title order={2}>Lead Details</Title>
          <Group>
            <Button
              variant={'outline'}
              leftIcon={<IconFileInvoice />}
              onClick={() => {
                uploadModalHelper.open();
              }}
            >
              Upload Invoice
            </Button>
            <Button
              variant={'outline'}
              loading={handleDownloadLead.isLoading}
              loaderProps={{
                children: 'Downloading...',
              }}
              leftIcon={<DownloadSvg />}
              onClick={() => {
                handleDownloadLead.mutateAsync();
              }}
            >
              {handleDownloadLead.isSuccess ? 'Downloaded Successfully 🎉' : 'Download'}
            </Button>
          </Group>
        </Group>

        <LeadDataTable refetchKey={leadTableRefetchKey} />
      </Paper>
    </Stack>
  );
}

function LeadDataTable({ refetchKey }: { refetchKey: any }) {
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const handleSortStatusChange = (status: DataTableSortStatus) => {
    setPage(1);
    setSortStatus(status);
  };

  const leadQuery = useQuery(['dashboard-list', page, refetchKey], () => {
    return API.getLeads({ page: page }).then((res) => res.data);
  });

  const { records, page_size, total } = leadQuery.data?.data || {};

  // const handlUploadInvoice = useMutation(API.uploadInvoice, {
  //   onSuccess: (res) => {
  //     const title = res.data.message;
  //     notifications.success({ message: title });
  //     leadQuery.refetch();
  //     uploadModalHelper.close();
  //   },
  //   onError: (err) => {
  //     notifications.error({ title: 'Failed to submit invoice', message: getErrorMessage(err) });
  //   },
  // });

  // const [selectedRecord, setSelectedRecord] = useState(null as any);
  // const [files, setfiles] = useState([] as FileWithPath[]);
  // console.log(selectedRecords);

  // const [uploadModalOpened, uploadModalHelper] = useDisclosure(false);
  // const handleCloseUploadInvoice = () => {
  //   setSelectedRecord(null);
  //   setfiles([]);
  //   uploadModalHelper.close();
  // };

  return (
    <>
      {/* <Modal
        size={'xl'}
        opened={uploadModalOpened}
        onClose={handleCloseUploadInvoice}
        title={
          <Group>
            <ThemeIcon>
              <IconUpload size={'1em'} />
            </ThemeIcon>
            <Text>Upload Invoice</Text>
          </Group>
        }
        keepMounted={false}
      >
        {selectedRecord ? (
          <Stack style={{ gap: 1 }} mb="lg">
            <Textarea
              variant="filled"
              label="Learner Name"
              value={selectedRecord.display_name}
            ></Textarea>
            <Textarea
              variant="filled"
              label="Course Name"
              value={selectedRecord.course_title + ' (' + selectedRecord.course_ref + ')'}
            ></Textarea>
            <TextInput
              variant="filled"
              label="Amount"
              value={
                selectedRecord.sales_revenue
                  ? '$ ' + new Intl.NumberFormat().format(selectedRecord.sales_revenue)
                  : '-'
              }
            ></TextInput>
          </Stack>
        ) : null}

        <Dropzone
          multiple={false}
          onDrop={(files) => {
            console.log('accepted files', files);
            if (files.length >= 1) {
              setfiles([files[0]]);
            }
          }}
          onReject={(files) => console.log('rejected files', files)}
          // 10 * 1024 ** 2 = 10 MB
          maxSize={10 * 1024 ** 2}
          accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
        >
          <Group
            position="center"
            spacing="xl"
            style={{ minHeight: rem(220), pointerEvents: 'none' }}
          >
            <Dropzone.Accept>
              <IconUpload size="3.2rem" stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size="3.2rem" stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto size="3.2rem" stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                Drag images here or click to select files
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Attach as many files as you like, each file should not exceed 5mb
              </Text>
            </div>
          </Group>
        </Dropzone>
        <Stack px={'sm'} py="md">
          {files.map((file, index) => {
            return (
              <Group key={index.toString()}>
                <IconFile></IconFile>
                <Text>{file.path}</Text>
              </Group>
            );
          })}
        </Stack>
        <Button
          loading={handlUploadInvoice.isLoading}
          disabled={files.length === 0}
          fullWidth
          onClick={() => {
            if (files.length === 0) return;
            handlUploadInvoice.mutateAsync({
              invoice_pdf: files[0],
              learner_id: selectedRecord.learner_id,
            });
          }}
          mt="md"
        >
          Upload an invoice
        </Button>
      </Modal> */}

      <DataTable
        withBorder={false}
        fetching={leadQuery.isLoading}
        withColumnBorders={false}
        rowBorderColor={'transparent'}
        striped
        fontSize={'md'}
        highlightOnHover
        records={records || []}
        // Pagination
        page={page}
        onPageChange={setPage}
        totalRecords={total}
        recordsPerPage={page_size || 25}
        sortStatus={sortStatus}
        onSortStatusChange={handleSortStatusChange}
        // End pagination
        columns={columnsDefsArray as any}
      />
    </>
  );
}
