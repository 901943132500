import { Button, Code, Stack } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconLink } from '@tabler/icons-react';
import { notifications } from 'notifications';

export const ReferralLinks = ({
  referral_link,
  referral_link_with_contact_us,
}: {
  referral_link: string;
  referral_link_with_contact_us: string;
}) => {
  const clipboard = useClipboard();
  return (
    <Stack w="100%">
      <Button
        variant="gradient"
        leftIcon={<IconLink />}
        onClick={() => {
          notifications.success({
            message: 'Copied: ' + referral_link,
          });
          clipboard.copy(referral_link);
        }}
        gradient={{ from: 'teal', to: 'blue', deg: 60 }}
      >
        Checkout Link
      </Button>
      {clipboard.error ? (
        <Code
          w="100%"
          style={{
            fontSize: '12px',
            wordBreak: 'break-word',
          }}
        >
          {referral_link}
        </Code>
      ) : null}

      <Button
        variant="subtle"
        bg="#FFFBA7"
        leftIcon={<IconLink />}
        sx={() => {
          return {
            color: 'black',
            '&:hover': {
              backgroundColor: '#FFFCC2',
            },
          };
        }}
        onClick={() => {
          notifications.success({
            message: 'Copied: ' + referral_link_with_contact_us,
          });
          clipboard.copy(referral_link_with_contact_us);
        }}
      >
        Lead Form Link
      </Button>
      {clipboard.error ? (
        <Code
          w="100%"
          style={{
            fontSize: '12px',
            wordBreak: 'break-word',
          }}
        >
          {referral_link_with_contact_us}
        </Code>
      ) : null}
    </Stack>
  );
};
