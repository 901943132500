// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// const middleware = [thunk];

// const store = createStore(
// 	rootReducer,
// 	composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { persistStore } from 'redux-persist';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
