import { NotificationProps, notifications as _notifications } from '@mantine/notifications';
import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';

export const notifications = {
  ..._notifications,
  success: (props: NotificationProps) => {
    _notifications.show({
      withCloseButton: true,
      color: 'green',
      icon: <IconCircleCheck />,
      loading: false,
      ...props,
    });
  },
  error: (props: NotificationProps) => {
    _notifications.show({
      withCloseButton: true,
      color: 'red',
      icon: <IconAlertCircle />,
      ...props,
    });
  },
};
