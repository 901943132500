import {
  Box,
  Center,
  SimpleGrid,
  TextInput,
  Title,
  Group,
  PasswordInput,
  BackgroundImage,
  Button,
  Drawer,
} from '@mantine/core';
import { IconLock, IconUserCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import LoginImage from 'assets/Login/bg.jpg';
import LocateImage from 'assets/Login/locate.png';
import { ReactComponent as FCALogo } from 'assets/Login/logo.svg';
import { ReactComponent as TwentyFourHour } from 'assets/Login/24hrs.svg';
import TrainerImage from 'assets/Login/DK.png';
import { useForm, zodResolver } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { API } from 'services';
import { setLoginUser } from 'redux/reducers/auth';
import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from 'services/getErrorMessage';
import { notifications } from 'notifications';
import { z } from 'zod';
import { GradientButton } from 'components/GradientButton';
import { ForgotPassword } from './ForgetPasswordModal';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import styles from './LoginPage.module.scss';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

type FormValue = z.infer<typeof schema>;

export const LoginPage = () => {
  const { loginUser } = useLoginUser();
  const [loginFormVisible, setLoginFormVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgetPasswordOpened, forgetPasswordModalHelper] = useDisclosure(false);
  const isMobileMode = useMediaQuery('(max-width: 1023px)');
  const mobile_floating_offset = 150;
  const form = useForm({
    initialValues: import.meta.env.DEV
      ? {
          email: 'jackng@firstcom.com.sg',
          password: '86294024',
        }
      : {
          email: '',
          password: '',
        },
    validate: zodResolver(schema),
  });

  const handleLogin = useMutation(
    (values: FormValue) => {
      return API.login({
        email: values.email,
        password: values.password,
      })
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          dispatch(
            setLoginUser({
              token: res.data.token,
              user: res.data.user,
            })
          );

          navigate('/dashboard', { replace: true });
          return res.data;
        });
    },
    {
      onError: (error) => {
        notifications.error({
          title: 'Login Failed',
          message: getErrorMessage(error),
        });
      },
    }
  );

  useEffect(() => {
    if (loginUser) {
      navigate('/dashboard', { replace: true });
    }
  }, []);

  const loginForm = (
    <Center
      h="100%"
      style={
        isMobileMode
          ? {
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }
          : {
              flexDirection: 'column',
            }
      }
    >
      {isMobileMode && !loginFormVisible && (
        <Button
          onClick={() => {
            setLoginFormVisible(!loginFormVisible);
          }}
        >
          Sign In
        </Button>
      )}

      {/* <Title color={'rgb(15, 24, 32)'} className="font-coolvetica" weight={600}>
        Sign Up & Earn
      </Title> */}
      <Title className="font-lobster with-stroke">Partner Portal</Title>
      <p className={`font-poppins ${styles.signInText}`}>Sign In</p>
      <form
        onSubmit={form.onSubmit((values) => handleLogin.mutateAsync(values))}
        style={{
          marginTop: 10,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <TextInput
          {...form.getInputProps('email')}
          size="lg"
          w={'80%'}
          maw={400}
          mb={10}
          label="Email"
          icon={<IconUserCircle color="#cccccc" />}
          placeholder="Email@example.com"
          classNames={{ label: styles.label, input: styles.input, wrapper: styles.wrapper }}
        />

        <PasswordInput
          {...form.getInputProps('password')}
          size="lg"
          w={'80%'}
          maw={400}
          label="Password"
          icon={<IconLock color="#cccccc" />}
          placeholder="Password"
          classNames={{ label: styles.label, input: styles.input, wrapper: styles.wrapper }}
        />

        <Group position="right" w={'80%'} maw={400}>
          <Button
            unstyled
            component="a"
            onClick={() => {
              forgetPasswordModalHelper.open();
            }}
            classNames={{ root: styles.forgetpassword_label }}
          >
            Forget Password
          </Button>
        </Group>

        <GradientButton
          type="submit"
          loading={handleLogin.isLoading}
          size="md"
          w={'80%'}
          mt={20}
          maw={300}
        >
          Sign In
        </GradientButton>
      </form>
      <ForgotPassword opened={forgetPasswordOpened} onClose={forgetPasswordModalHelper.close} />
    </Center>
  );

  return (
    <>
      <SimpleGrid
        cols={2}
        w="100%"
        h="100vh"
        style={{ gap: 0 }}
        breakpoints={[
          {
            maxWidth: 'md',
            cols: 1,
          },
        ]}
      >
        <Box h={{ md: '100%' }}>
          <BackgroundImage
            src={LoginImage}
            style={{
              backgroundPosition: 'right bottom',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <div
              className={styles.ribbon}
              style={
                isMobileMode
                  ? {
                      bottom: `${mobile_floating_offset + 100}px`,
                      right: 0,
                    }
                  : {}
              }
            >
              <p>
                Learn From Certified Trainers with Years of Proven Training and Industry Experience.
              </p>
            </div>
            <Center w="100%" h={'100%'} style={{ overflow: 'hidden' }}>
              <div
                className={styles.main_figure}
                style={
                  isMobileMode
                    ? {
                        top: 0,
                      }
                    : {}
                }
              >
                <img
                  className={styles.locate_image}
                  src={LocateImage}
                  alt=""
                  style={isMobileMode ? { bottom: mobile_floating_offset } : {}}
                />
                <img
                  className={styles.dk_image}
                  src={TrainerImage}
                  alt=""
                  style={isMobileMode ? { bottom: mobile_floating_offset } : {}}
                />
              </div>
            </Center>
            <div className={styles.twenty_four}>
              <TwentyFourHour width={'100%'} height={'auto'} />
            </div>
            <div className={styles.fca_logo}>
              <FCALogo width={'100%'} height={'auto'} />
            </div>
          </BackgroundImage>
        </Box>
        <Box
          // bg="#F5FAFE"
          style={
            isMobileMode
              ? {
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  zIndex: 11,
                  overflowY: 'visible',
                }
              : {}
          }
        >
          <Box
            h="100%"
            style={
              isMobileMode
                ? {
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '20px 0',
                    borderRadius: '20px 20px 0 0',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                  }
                : {
                    flexDirection: 'column',
                  }
            }
          >
            {isMobileMode ? (
              <Center style={{ flexDirection: 'column' }} h="100%">
                {/* <Title color={'rgb(15, 24, 32)'} className="font-coolvetica" weight={600}>
                  Sign Up & Earn
                </Title> */}
                <Title className="font-lobster with-stroke">{`Partner's Portal`}</Title>
                <Button
                  mt="lg"
                  variant="gradient"
                  size="lg"
                  onClick={() => {
                    setLoginFormVisible(!loginFormVisible);
                  }}
                >
                  Sign In
                </Button>
              </Center>
            ) : (
              <>{loginForm}</>
            )}
          </Box>
        </Box>
      </SimpleGrid>
      <Drawer
        size={'full'}
        position="bottom"
        opened={loginFormVisible}
        onClose={() => setLoginFormVisible(false)}
      >
        {loginForm}
      </Drawer>
    </>
  );
};
