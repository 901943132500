import { useMediaQuery } from '@mantine/hooks';

export const useBreakpoints = (defaultValue?: boolean) => {
  const xs = useMediaQuery('screen and (min-width: 0em)', defaultValue);
  const sm = useMediaQuery('screen and (min-width: 48em)', defaultValue);
  const md = useMediaQuery('screen and (min-width: 64em)', defaultValue);
  const lg = useMediaQuery('screen and (min-width: 74em)', defaultValue);
  const xl = useMediaQuery('screen and (min-width: 78em)', defaultValue);

  return { xs, sm, md, lg, xl };
};
