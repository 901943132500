import { Box, Center, Image } from '@mantine/core';
import WelcomeSvg from 'assets/dashboard/img_welcome02.svg';
import { ReactComponent as GearSvg } from 'assets/dashboard/icon_gear.svg';
import { ReactComponent as StarSvg } from 'assets/dashboard/icon_star.svg';
import styles from './DashboarPage.module.scss';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export function WelcomeFloatingImage() {
  // dynamic height of image and floating image
  const [targetHeight, setTargetHeight] = useState(0);
  const [targetWidth, setTargetWidth] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleResize = debounce(() => {
    const targetDiv = document.getElementById('welcome-image');
    if (targetDiv) {
      console.log(targetDiv.clientHeight);
      setTargetHeight(targetDiv.clientHeight);
      setTargetWidth(targetDiv.clientWidth);
    }
  }, 500);

  useEffect(() => {
    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize(); // Initial calculation
  }, [imageLoaded]);

  return (
    <Box display={'flex'} py={20} style={{ flexGrow: 1, position: 'relative' }}>
      <Center>
        <img
          id={'welcome-image'}
          className={styles.welcome_image}
          src={WelcomeSvg}
          alt="welcome"
          onLoad={() => setImageLoaded(true)}
        />
        <Box
          className={styles.welcome_floating_container}
          style={{ height: `${targetHeight}px`, width: `${targetWidth}px` }}
        >
          <Box className={`${styles.item_1} ${styles.rotate_2}`}>
            <GearSvg className={styles.floating_image} />
          </Box>
          <Box className={`${styles.item_2} ${styles.rotate_1}`}>
            <GearSvg className={styles.floating_image} />
          </Box>
          <Box className={`${styles.item_3} ${styles.scale_1}`}>
            <StarSvg className={styles.floating_image} />
          </Box>
          <Box className={`${styles.item_4} ${styles.star_animation}`}>
            <StarSvg className={styles.floating_image} />
          </Box>
        </Box>
      </Center>
    </Box>
  );
}
