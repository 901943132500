import {
  Stack,
  Title,
  Group,
  Paper,
  Button,
  Anchor,
  Breadcrumbs,
  TextInput,
  ActionIcon,
} from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { API } from 'services';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IconDeviceFloppy,
  IconGift,
  IconLockAccess,
  IconMail,
  IconPhone,
  IconRefresh,
  IconUser,
} from '@tabler/icons-react';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { getErrorMessage } from 'services/getErrorMessage';
import { notifications } from 'notifications';
import { modals } from '@mantine/modals';
import DashboardIcon from 'components/icons/DashboardIcon';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { ResetPasswordModal } from './ResetPasswordModal';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from 'redux/reducers/auth';

const schema = z.object({
  name: z
    .string()
    .max(255, { message: 'Name is too long' })
    .nonempty({ message: 'Name is required' }),
  // email: z.string().email({ message: 'Invalid email' }),
  mobile: z
    .string()
    .max(255, { message: 'Mobile is too long' })
    .nonempty({ message: 'Mobile is required' }),
});

type FormValue = z.infer<typeof schema>;

export function ProfilePage() {
  const dispatch = useDispatch();
  const { loginUser } = useLoginUser();
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm<FormValue>({
    validate: zodResolver(schema),
    initialValues: {
      // email: loginUser.partner_user_email,
      mobile: loginUser.partner_user_mobile,
      name: loginUser.partner_user_name,
    },
  });
  const items = [
    {
      title: (
        <ActionIcon>
          <DashboardIcon />
        </ActionIcon>
      ),
      href: '/dashboard',
    },
    { title: 'Profile', href: location.pathname },
  ].map((item, index) => (
    <Anchor
      key={index}
      onClick={() => {
        navigate(item.href);
      }}
    >
      {item.title}
    </Anchor>
  ));

  const [resetPasswordOpened, resetPasswordModalHelper] = useDisclosure(false);
  const handleSubmit = useMutation(
    (values: FormValue) => {
      return API.updateProfile({
        name: values.name,
        mobile: values.mobile,
      }).then((response) => {
        dispatch(fetchUserInfoThunk() as any);
        return response;
      });
    },
    {
      onSuccess: (response) => {
        const responseBody = response.data;
        modals.openConfirmModal({
          title: '🎉 Updated',
          children: responseBody.message,
          labels: {
            cancel: 'Back',
            confirm: (
              <>
                <span>
                  <IconRefresh />
                </span>
                <span>Refresh</span>
              </>
            ),
          },
          onConfirm: () => {
            navigate(0);
          },
        });
      },
      onError: (error) => {
        notifications.error({
          message: getErrorMessage(error),
        });
      },
    }
  );

  return (
    <Stack px="lg">
      <Breadcrumbs>{items}</Breadcrumbs>
      <Paper p="sm">
        <Group position="left" align="center" mb="lg">
          <Title order={1}>Welcome {loginUser.partner_user_name}</Title>
        </Group>

        <form>
          <Stack maw={400} w="100%">
            <TextInput
              w="100%"
              icon={<IconUser />}
              label="Name"
              placeholder="Name"
              {...form.getInputProps('name')}
            />
            <TextInput
              w="100%"
              variant="filled"
              icon={<IconMail />}
              contentEditable={false}
              label="Email"
              placeholder="Email"
              value={loginUser.partner_user_email}
            />
            <TextInput
              w="100%"
              icon={<IconPhone />}
              label="Mobile"
              placeholder="Mobile"
              {...form.getInputProps('mobile')}
            />
            <TextInput
              variant="filled"
              contentEditable={false}
              w="100%"
              icon={<IconGift />}
              label="Code"
              placeholder="Code"
              value={loginUser.referral_code}
            />

            <Group mt={'lg'} position="right">
              <Button
                disabled={!form.isDirty()}
                leftIcon={<IconDeviceFloppy />}
                onClick={() => {
                  if (!form.validate().hasErrors) {
                    handleSubmit.mutateAsync(form.values);
                  }
                }}
              >
                Save
              </Button>
            </Group>

            <hr />

            <Group>
              <ResetPasswordModal
                opened={resetPasswordOpened}
                onClose={resetPasswordModalHelper.close}
              />
              <Button
                w="100%"
                variant="outline"
                leftIcon={<IconLockAccess />}
                onClick={() => {
                  // resetPasswordModalHelper.open();
                  modals.openConfirmModal({
                    size: 'mlg',
                    title: 'Reset Password',
                    children: 'Are you sure you want to reset your password ',
                    color: 'red',
                    confirmProps: { color: 'red' },
                    labels: {
                      cancel: 'Cancel',
                      confirm: 'Yes, I am sure',
                    },
                    onConfirm() {
                      resetPasswordModalHelper.open();
                    },
                  });
                }}
              >
                Change Password
              </Button>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
}
