import { Layout } from 'components/layout/Layout';
import { DashboardPage } from 'pages/DashboardPage/DashboardPage';
import { LoginPage } from 'pages/Login/LoginPage';
import { ProfilePage } from 'pages/Profile/ProfilePage';
import { CreateUserPage } from 'pages/UserManagement/CreateUserPage';
import { UserDetailPage } from 'pages/UserManagement/UserDetailPage';
import { UserManagementPage } from 'pages/UserManagement/UserManagementPage';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';

// const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
// const router = sentryCreateBrowserRouter(route);
const route: RouteObject[] = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
      },
      {
        path: '/',
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'user-management',
        element: <UserManagementPage />,
      },
      {
        path: 'user-management/create',
        element: <CreateUserPage />,
      },
      {
        path: 'user/:user_id',
        element: <UserDetailPage />,
      },
    ],
  },
];

const router = createBrowserRouter(route);

export default router;
