import {
  Modal,
  Paper,
  Text,
  Title,
  Group,
  rem,
  Stack,
  Badge,
  TextInput,
  Button,
  Space,
  Collapse,
  Center,
  Divider,
  ActionIcon,
} from '@mantine/core';
import { FileWithPath, Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import {
  IconUpload,
  IconX,
  IconFileInvoice,
  IconPdf,
  IconFile,
  IconCloudUpload,
  IconMoodEmpty,
  IconList,
  IconEyeOff,
  IconInfoCircle,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { API } from 'services';
import { getErrorMessage } from 'services/getErrorMessage';
import { format_currency } from 'utils/format';
import { columnsDefsArray } from './columnsRenderer';
import { notifications } from 'notifications';
import { DataTable } from 'components/DataTable';
import { useDisclosure } from '@mantine/hooks';

export function UploadInvoiceForm(props: any) {
  const { selectedRecords, setSelectedRecords } = props;
  const [invoiceNumber, setInvoieNumber] = useState('');
  const [files, setfiles] = useState([] as FileWithPath[]);
  const [showSearch, showSearchHelper] = useDisclosure(true);

  const leadOptionQuery = useQuery(
    ['upload-invoice-lead-option'],
    () => {
      return API.getLeadOptionForUploadInvoice().then((res) => res.data.data);
    },
    {
      enabled: props.opened && showSearch,
    }
  );
  const searchResult = leadOptionQuery.data;

  const estimatedCommission = selectedRecords
    ? selectedRecords.reduce((acc: number, item: any) => {
        return acc + parseInt(item.commission);
      }, 0)
    : null;

  const invoiceInputRef = useRef<HTMLInputElement>(null);

  // Workaround: modal not being discount after close
  //             causing all the state being persereved
  useEffect(() => {
    if (!props.opened) {
      setfiles([]);
      setSelectedRecords([]);
      showSearchHelper.open();
      handlUploadInvoice.reset();
    }
  }, [props.opened]);

  const handleSubmit = () => {
    if (!selectedRecords.length) {
      notifications.error({
        title: 'Failed to submit invoice',
        message: 'Please select at least 1 learner',
      });
      return;
    }

    if (!files.length) {
      notifications.error({ title: 'Failed to submit invoice', message: 'Please select a file' });
      return;
    }

    if (!invoiceNumber) {
      notifications.error({ title: 'Validation Error', message: 'Please enter invoice number' });
      invoiceInputRef.current?.focus();
      return;
    }

    if (invoiceNumber.length < 5) {
      notifications.error({
        title: 'Validation Error',
        message: 'Invalid invoice number, should be more than 5 character',
      });
      invoiceInputRef.current?.focus();
      invoiceInputRef.current?.select();
      return;
    }

    handlUploadInvoice.mutateAsync({
      invoice_pdf: files[0],
      invoice_no: invoiceNumber,
      learner_id: selectedRecords.map((item: any) => item.learner_id),
    });

    notifications.success({
      message: 'Submitting invoice, please wait...',
    });
  };

  const handlUploadInvoice = useMutation(API.uploadInvoice, {
    onSuccess: (res) => {
      const title = res.data.message;
      notifications.success({ message: title });
      props.onClose();
      setTimeout(() => {
        setfiles([]);
        setSelectedRecords([]);
        showSearchHelper.open();
        handlUploadInvoice.reset();
        props.onSuccess();
      }, 1000);
    },
    onError: (err) => {
      notifications.error({ title: 'Failed to submit invoice', message: getErrorMessage(err) });
    },
  });

  return (
    <Modal
      key={props.opened ? 'opened' : 'closed'}
      title="Upload Invoice"
      size={'90vw'}
      closeOnEscape={false}
      closeOnClickOutside={false}
      keepMounted={false}
      opened={props.opened}
      onClose={props.onClose}
    >
      <Paper py={'25px'}>
        <Group pb="sm">
          <Title size={'sm'}>Ready for Payout</Title>
          <Button
            leftIcon={showSearch ? <IconEyeOff /> : <IconList />}
            variant="outline"
            size="xs"
            display={'inline'}
            onClick={showSearchHelper.toggle}
          >
            {showSearch ? 'Hide' : 'Show List'}
          </Button>
        </Group>
        <Collapse in={showSearch}>
          <DataTable
            fontSize={'xs'}
            fetching={leadOptionQuery.isFetching}
            records={searchResult || []}
            columns={columnsDefsArray as any}
            scrollAreaProps={{ type: 'hover', style: { paddingBottom: 25 } }}
            idAccessor={'learner_id_unique'}
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            minHeight={'100px'}
            noRecordsText="No Learner to be Selected"
            noRecordsIcon={<IconMoodEmpty />}
          />
        </Collapse>
        {!showSearch ? (
          <Text mb="lg" size={'sm'} color="dimmed">
            List Hidden
          </Text>
        ) : (
          <Divider my={'xl'} mx={-20} />
        )}

        {selectedRecords && selectedRecords.length ? (
          <>
            <Title size={'sm'} mb="lg">
              Selected Learners{' '}
              {selectedRecords && selectedRecords.length ? `(${selectedRecords.length})` : null}
            </Title>
            <DataTable
              fontSize={'xs'}
              mb={'lg'}
              minHeight={'100px'}
              sx={() => {
                return {
                  '& th': {
                    backgroundColor: '#81F3CE',
                  },
                };
              }}
              withBorder
              records={selectedRecords || []}
              columns={columnsDefsArray as any}
            />

            <Stack mt="lg" justify="center" align="center">
              <Paper>
                <Group position="right">
                  <Text size={'sm'}>Total Commision (Based on Selection):</Text>
                  <Badge
                    display={'inline-block'}
                    size="xl"
                    radius={'sm'}
                    variant="filled"
                    className="green"
                  >
                    {format_currency(estimatedCommission)}
                  </Badge>
                </Group>
              </Paper>
            </Stack>
          </>
        ) : null}

        <Collapse in={selectedRecords.length}>
          {!files.length ? (
            <Center mt="lg">
              <Dropzone
                w="300px"
                multiple={false}
                onDrop={(files) => {
                  console.log('accepted files', files);
                  if (files.length >= 1) {
                    setfiles([files[0]]);
                    setTimeout(() => {
                      invoiceInputRef.current?.focus();
                    }, 300);
                  }
                }}
                onReject={(files) => console.log('rejected files', files)}
                // 10 * 1024 ** 2 = 10 MB
                maxSize={10 * 1024 ** 2}
                accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
              >
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: rem(60), pointerEvents: 'none' }}
                >
                  <Dropzone.Accept>
                    <IconUpload size="3.2rem" stroke={1.5} />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX size="3.2rem" stroke={1.5} />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconFileInvoice size="1.6rem" stroke={1.5} />
                    <IconPdf size="1.6rem" stroke={1.5} />
                  </Dropzone.Idle>
                  <div>
                    <Button size={'md'} variant="light" leftIcon={<IconInfoCircle />}>
                      Select a invoice
                    </Button>
                  </div>
                </Group>
              </Dropzone>
            </Center>
          ) : (
            <Center>
              <Stack mt="md" align="center">
                {files.map((file, index) => {
                  return (
                    <Badge key={file.path} size="lg" color="violet" variant="light">
                      <Group>
                        <IconFile size={'1.2em'} />
                        <Text>{file.path}</Text>
                        <ActionIcon
                          size={'sm'}
                          onClick={() => {
                            setfiles([]);
                          }}
                        >
                          <IconX />
                        </ActionIcon>
                      </Group>
                    </Badge>
                  );
                })}
                <TextInput
                  ref={invoiceInputRef}
                  w={'300px'}
                  mb="md"
                  icon={<IconFileInvoice />}
                  placeholder="Your Invoice Number"
                  onChange={(event) => {
                    setInvoieNumber(event.target.value);
                  }}
                  value={invoiceNumber}
                  label={
                    <>
                      <span>Invoice Number *</span>
                    </>
                  }
                />
              </Stack>
            </Center>
          )}
        </Collapse>

        <Space h="md" />

        {selectedRecords.length ? (
          <Center mt="md">
            <Button
              size={'md'}
              variant="gradient"
              leftIcon={<IconCloudUpload />}
              onClick={handleSubmit}
              loading={handlUploadInvoice.isLoading}
            >
              Submit Invoice
            </Button>
          </Center>
        ) : (
          <Center>
            <Button size={'md'} variant="light" leftIcon={<IconInfoCircle />}>
              Select a record first
            </Button>
          </Center>
        )}
      </Paper>
    </Modal>
  );
}
