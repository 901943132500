import { axios } from './axios';

export const login = (data: { email: string; password: string }) => {
  return axios.post('/partner-api/login', data);
};

export const logout = () => {
  return axios.post('/partner-api/logout');
};

export const getUser = () => {
  return axios.get('/partner-api/get_user');
};

export const updateProfile = (data: { name: string; mobile: string }) => {
  return axios.post('/partner-api/update_profile', data);
};

export const forgetpassword = (data: { email: string }) => {
  return axios.post('/partner-api/request_forgetpassword_otp', data);
};

export const verifyPasswordOTP = (data: { email: string; otp: string }) => {
  return axios.post('/partner-api/verify_forgetpassword_otp', data);
};

export const resetPassword = (data: {
  email?: string;
  otp: string;
  password: string;
  password_confirmation: string;
}) => {
  return axios.post('/partner-api/reset_password', data);
};

export const resetPasswordFirstTime = (data: {
  password: string;
  password_confirmation: string;
}) => {
  return axios.post('/partner-api/reset_password_firsttime', data);
};

export const getDashboard = () => {
  return axios.get('/partner-api/dashboard');
};

export const getLeads = (
  params: { page?: number | string; output_type?: 'download' } | null = null
) => {
  return axios.get('/partner-api/lead', {
    params: params,
  });
};

export const getUserList = (query: Record<string, string | number | null | undefined>) => {
  return axios.get('/partner-api/admin/users', {
    params: query,
  });
};

export const getUserDetail = (id: string) => {
  return axios.get('/partner-api/admin/user/' + id);
};

export const createUser = (data: {
  name: string;
  mobile: string;
  email: string;
  password: string;
}) => {
  return axios.post('/partner-api/admin/user/create', data);
};

export const updateUser = (data: {
  user_id: string;
  name: string;
  status: string;
  mobile: string;
  email: string;
}) => {
  return axios.post('/partner-api/admin/user/update', data);
};

export const getLeadOptionForUploadInvoice = () => {
  return axios.get('/partner-api/invoice/option_for_create');
};

export const uploadInvoice = (data: {
  invoice_pdf: File;
  invoice_no: string;
  learner_id: string | string[];
}) => {
  const formData = new FormData();
  formData.append('invoice_pdf', data.invoice_pdf);
  formData.append('invoice_no', data.invoice_no);

  let learner_id = data.learner_id;
  if (!Array.isArray(learner_id)) {
    learner_id = [learner_id];
  }

  learner_id.forEach((id) => {
    formData.append('learner_id[]', id);
  });

  // formData.append('learner_id', data.learner_id as any);

  return axios.post('/partner-api/invoice/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
