import {
  Title,
  Button,
  Container,
  Group,
  Center,
  Box,
  Modal,
  ModalProps,
  PasswordInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { notifications } from 'notifications';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from 'redux/reducers/auth';
import { API } from 'services';
import { z } from 'zod';

const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .nonempty({ message: 'Password is required' })
      .superRefine((data, ctx) => {
        const errors = [];
        if (!/[a-z]/.test(data)) errors.push('- Password must contain a lowercase letter.');
        if (!/[A-Z]/.test(data)) errors.push('- Password must contain an uppercase letter.');
        if (!/[0-9]/.test(data)) errors.push('- Password must contain a number.');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(data)) errors.push('- Password must contain a symbol.');
        if (data.length < 8) errors.push('- Password must be at least 8 characters long.');
        if (errors.length === 0) {
          return true;
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.join('\n'),
          });
        }
      }),
    password_confirmation: z.string().nonempty({ message: 'Password confirmation is required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'], // path of error
  });

type ResetPasswordFirstTimeFormValue = z.infer<typeof resetPasswordSchema>;

export function ResetPasswordFirstTimeModal(props: ModalProps) {
  const dispatch = useDispatch();
  const handleResetPasswordFirsttime = useMutation(API.resetPasswordFirstTime, {
    onSuccess: () => {
      dispatch(fetchUserInfoThunk() as any).finally(() => {
        notifications.success({
          message: 'Reset password successfully.',
        });
        props.onClose();
        handleResetPasswordFirsttime.reset();
      });
    },
  });

  const resetPasswordForm = useForm<ResetPasswordFirstTimeFormValue>({
    validate: zodResolver(resetPasswordSchema),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (!props.opened) {
      handleResetPasswordFirsttime.reset();
    }
  }, [props.opened]);

  return (
    <Modal {...props} size={'xl'} closeOnClickOutside={false} keepMounted={false}>
      <Center>
        <Container w="100%" my={30}>
          <Title size={'md'} ta="center">
            Please update your password
          </Title>

          <Box mt={50}>
            <form
              onSubmit={resetPasswordForm.onSubmit((values) =>
                handleResetPasswordFirsttime.mutateAsync({
                  password: values.password,
                  password_confirmation: values.password_confirmation,
                })
              )}
            >
              <PasswordInput
                label="New Password"
                style={{ whiteSpace: 'normal' }}
                {...resetPasswordForm.getInputProps('password')}
                sx={() => ({
                  '.mantine-InputWrapper-error': {
                    whiteSpace: 'break-spaces',
                  },
                })}
              />

              <PasswordInput
                label="Confirm Password"
                {...resetPasswordForm.getInputProps('password_confirmation')}
              />

              <Group position="apart" mt="lg">
                <Button w="100%" loading={handleResetPasswordFirsttime.isLoading} type="submit">
                  Update Password
                </Button>
              </Group>
            </form>
          </Box>
        </Container>
      </Center>
    </Modal>
  );
}
