import {
  Stack,
  Title,
  Group,
  Paper,
  Button,
  Anchor,
  Breadcrumbs,
  TextInput,
  PasswordInput,
  ActionIcon,
} from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { API } from 'services';
import { useNavigate } from 'react-router-dom';
import { IconLock, IconMail, IconPhone, IconUser, IconUserPlus } from '@tabler/icons-react';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { getErrorMessage } from 'services/getErrorMessage';
import { notifications } from 'notifications';
import { modals } from '@mantine/modals';
import DashboardIcon from 'components/icons/DashboardIcon';

const schema = z.object({
  name: z
    .string()
    .max(255, { message: 'Name is too long' })
    .nonempty({ message: 'Name is required' }),
  email: z.string().email({ message: 'Invalid email' }),
  mobile: z
    .string()
    .max(255, { message: 'Mobile is too long' })
    .nonempty({ message: 'Mobile is required' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

type FormValue = z.infer<typeof schema>;

export function CreateUserPage() {
  const navigate = useNavigate();

  const form = useForm<FormValue>({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      password: '',
    },
    validate: zodResolver(schema),
  });

  const items = [
    {
      title: (
        <ActionIcon>
          <DashboardIcon />
        </ActionIcon>
      ),
      href: '/dashboard',
    },
    { title: 'User Management', href: '/user-management' },
    { title: 'Create', href: '/user-management/create' },
  ].map((item, index) => (
    <Anchor
      key={index}
      onClick={() => {
        navigate(item.href);
      }}
    >
      {item.title}
    </Anchor>
  ));

  const handleSubmit = useMutation(
    (values: FormValue) => {
      return API.createUser({
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        password: values.password,
      });
    },
    {
      onSuccess: (response) => {
        const responseBody = response.data;
        const user_id = responseBody.data.partner_user_id;
        let isConfirm = false;
        modals.openConfirmModal({
          title: '🎉 User Created',
          children: responseBody.message,
          labels: {
            cancel: 'Back',
            confirm: (
              <>
                <span>
                  <IconUserPlus />
                </span>
                <span>View User</span>
              </>
            ),
          },
          onConfirm: () => {
            isConfirm = true;
            navigate(`/user/${user_id}`, { replace: true });
          },
          onClose: () => {
            if (!isConfirm) {
              navigate(`/user-management`, { replace: true });
            }
          },
        });
      },
      onError: (error) => {
        notifications.error({
          message: getErrorMessage(error),
        });
      },
    }
  );

  return (
    <Stack px="lg">
      <Breadcrumbs>{items}</Breadcrumbs>
      <Paper p="sm">
        <Group position="left" align="center" mb="lg">
          <Title order={1}>Create User </Title>
        </Group>

        <form
          onSubmit={form.onSubmit((values) => {
            handleSubmit.mutateAsync(values);
          })}
        >
          <Stack maw={400} w="100%">
            <TextInput
              w="100%"
              icon={<IconUser />}
              label="Name"
              placeholder="Name"
              {...form.getInputProps('name')}
            />
            <TextInput
              w="100%"
              icon={<IconMail />}
              label="Email"
              placeholder="Email"
              {...form.getInputProps('email')}
            />
            <TextInput
              w="100%"
              icon={<IconPhone />}
              label="Mobile"
              placeholder="Mobile"
              {...form.getInputProps('mobile')}
            />
            <PasswordInput
              w="100%"
              icon={<IconLock />}
              label="Password"
              placeholder="Password"
              {...form.getInputProps('password')}
            />
            <Group mt={'lg'} position="right">
              <Button type="submit">Create</Button>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
}
