import { ActionIcon, Avatar, Group, Indicator, TextInput, Text, MediaQuery } from '@mantine/core';
import { IconBell, IconMenu2, IconSearch } from '@tabler/icons-react';
import { useNavbarContext } from './Navbar';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { useTheme } from '@emotion/react';

const emptyObject = {};

export const Header = () => {
  const navbar = useNavbarContext();
  const { xs, sm, md } = useBreakpoints(true);
  const { loginUser } = useLoginUser();
  const theme = useTheme();

  const menuButton = (
    <ActionIcon onClick={navbar.toggle}>
      <IconMenu2 size={'1.5rem'} />
    </ActionIcon>
  );

  return (
    <Group mb={20} px="lg" position={'apart'} style={{ flexWrap: 'nowrap' }}>
      <Group>
        {xs && !md ? menuButton : null}
        {/* <MediaQuery largerThan={'sm'} styles={emptyObject}>
          <TextInput
            display={{ base: 'none', sm: 'block' }}
            sx={() => {
              return {
                input: { border: 'none' },
              };
            }}
            className={'shadow-sm'}
            placeholder="Search"
            icon={<IconSearch size="0.8rem" />}
          />
        </MediaQuery> */}
      </Group>

      <Group style={{ gap: 0 }}>
        {/* <Indicator
          label="10"
          color="teal"
          sx={(theme) => ({
            '.mantine-Indicator-indicator': {
              fontSize: 7,
              fontWeight: 'bold',
              padding: '7px 4px',
              marginTop: 7,
              marginRight: 10,
            },
          })}
        >
          <ActionIcon color="teal" size={'xl'}>
            <IconBell size={'2.2rem'} />
          </ActionIcon>
        </Indicator> */}
        {/* <Avatar size={'lg'} src="avatar.png" alt="it's me" /> */}
        <Text>Welcome, {loginUser.partner_user_name}</Text>
      </Group>
    </Group>
  );
};
